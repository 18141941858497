<template>
    <div>
      <v-card>
        <v-card-title>
          <span class="headline">{{Accion}}</span>
          <v-spacer></v-spacer>
          <span v-if="Item.id != 0" class="headline">ID VENTA: {{Item.id}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
             <v-row>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.razon_social"
                  label="Punto de Venta"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.plataforma"
                  label="Plataforma"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.fecha_compra_format"
                  label="Fecha Compra"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.id_membresia"
                  label="ID Producto"
                ></v-text-field>
              </v-col>

             <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.userproducto"
                  label="Usuario"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.passproducto"
                  label="Contraseña"
                ></v-text-field>
              </v-col>

              

               <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.cliente"
                  label="Cliente"
                ></v-text-field>
              </v-col>

               <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.precio_compra"
                  label="Precio Compra"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.precio_venta"
                  label="Precio Venta"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.disponible_anterior"
                  label="Disponible Antes de Compra"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.disponible_actual"
                  label="Disponible Después de Compra"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.porc_comision"
                  label="Porcentaje De Comisión"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.dinero_comision"
                  label="Dinero de Comisión"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.comision_anterior"
                  label="Comisión Antes de Compra"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.comision_actual"
                  label="Comisión Después de Compra"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.puntos"
                  label="Puntos Asignados"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.puntos_anterior"
                  label="Puntos Antes de Compra"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.puntos_actual"
                  label="Puntos Después de Compra"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  rows="1"
                  counter
                  label="Notas"
                  v-model="Item.notas"
                ></v-textarea>
              </v-col>

            </v-row>
          </v-container>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="Ocultar()">Cerrar</v-btn>
        </v-card-actions>
      </v-card>

    </div>
</template>

<script>
import { mapState, mapGetters, mapActions} from 'vuex'

export default ({

  props:['Accion','Item','NuevoActivo'],

  watch:{
      Alerta(value){
        if(value.error == false){
          this.Ocultar()
        }
      }
  },

  created (){
    this.ConsultarPuntosDeVenta()
    this.ConsultarPerfiles()
  },

  data: () => ({
    var: null,
    DialogAceptar: false,

    ItemEnviar: {
            id: null,
            nombre: null,
            usuario: null,
            celular: null,
            email: null,
            punto_venta: null,
            encargado: null,
            perfil: null,
            notas: null,
            permisos: '',
            activo: null,
          },
  }),

  methods: {
     ...mapActions(['ConsultarPuntosDeVenta', 'ConsultarPerfiles', 'ConsultarUsuarios']),

    Ocultar(){
        this.$emit('NuevoActivo', false)
    }
  },

  computed:{
    ...mapState(['Alerta']),
    ...mapGetters(['SINO','DatosdeUsuario','PuntosDeVentaGetter','PerfilesGetter'])
  }


})
</script>
