<template>
  <div class="ventasstreaming">
    <tabla titulo="Ventas" 
          :headers = "headers"
          :arreglo = "VentasGetter.items"
            />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import tabla from '@/components/ventas/';

export default {
  name: 'ventasstreaming',

   components: {
    tabla
  },
  
  created(){
    this.ConsultarVentas()
  },

  data: () =>{
    return{
    headers: [
        { text: 'ID', align: 'start', value: 'id', sortable: true },
        { text: 'PUNTO DE VENTA', value: 'razon_social', sortable: true },
        { text: 'USUARIO', value: 'nombre_usuario', sortable: true },
        { text: 'CLIENTE', value: 'nombre_cliente', sortable: true },
        { text: 'CELULAR', value: 'cliente', sortable: true },
        { text: 'PLATAFORMA', value: 'plataforma', sortable: true },
        { text: 'CORREO', value: 'userproducto', sortable: true },
        { text: 'CONTRASEÑA', value: 'passproducto', sortable: true },
        { text: 'PERFIL', value: 'perfiltipo', sortable: true },
        { text: 'VALOR', value: 'precio_venta', sortable: true },
        { text: 'COMISIÓN', value: 'dinero_comision', sortable: true },
        { text: 'FECHA COMPRA', value: 'fecha_compra_format', sortable: true },
        { text: 'FECHA VENCE', value: 'fecha_vence_format', sortable: true },
        { text: 'CHEQUEADO', value: 'chequeado', sortable: true },
        { text: 'ACCIONES', value: 'actions', sortable: false },
      ],

    }
  },

  methods: {
    ...mapActions(['ConsultarVentas']),

  },

  computed:{
      ...mapGetters(['VentasGetter'])
  }
}
</script>
